//import axios from "axios";
import Cookies from "js-cookie";

//const ENDPOINT_PATH = "/api/v1/usuario/login";
export default {

  setUserLogged(Logged) {
    Cookies.set("token", Logged[0]);
    Cookies.set("customer", Logged[1]);
    Cookies.set("rut", Logged[2]);
    Cookies.set("userType", Logged[3]);
    Cookies.set("id", Logged[4]);
    //window.location.href = "http://localhost:8080";
    window.location.href = "https://sipymex.cl/";
  },
  getUserToken() {
    return Cookies.get("token");
  },
  getUserName() {
    return Cookies.get("customer");
  },
  getUserRut() {
    return Cookies.get("rut");
  },
  getUserType() {
    return Cookies.get("userType");
  },
  getUserId() {
    return Cookies.get("id");
  },
  exitSession() {
    Cookies.remove("token");
    Cookies.remove("customer");
    Cookies.remove("rut");
    Cookies.remove("userType");
    Cookies.remove("id");
    //window.location.href = "http://localhost:8080";
   window.location.href = "http://sipymex.cl/";
  },


};

/*export default {
    setUserLogged(Logged) {
    Cookies.set("stateApp", Logged[0]);
    Cookies.set("userRun", Logged[1]);
    Cookies.set("userRole", Logged[2]);
    Cookies.set("userName", Logged[3]);
    Cookies.set("ultimoAcceso", Logged[4]);
    Cookies.set("userType", Logged[5]);
  },
  getUserRun() {
    return Cookies.get("userRun");
  },

  getUserRole() {
    return Cookies.get("userRole");
  },

  getUserName() {
    return Cookies.get("userName");
  },

  getStateApp() {
    return Cookies.get("stateApp");
  },

  login(run, password, captchaResponse, aid) {
    let request = {
      run: run.replaceAll(".", ""),
      captchaResponse,
      clave: password,
      aid: aid,
    };
    let response = axios.post(ENDPOINT_PATH, request);
    return response;
  }};*/