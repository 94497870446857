import Vue from "vue";
import VueRouter from "vue-router";
// Landing

import Home from "@/views/landing/Home.vue";
import Layout from "@/components/Layout/Layout.vue";
import auth from "@/logic/auth";

// Periodos de Facturacion
/*import BillingPeriod from '@/views/billing_period/BillingPeriod.vue'
import CreateBillingPeriod from '@/views/billing_period/CreateBillingPeriod.vue'
import UpdateBillingPeriod from '@/views/billing_period/UpdateBillingPeriod.vue'
*/
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    name: "Layout",
    children: [
      {
        path: "/home",
        component: Home,
        name: "home",
        children: [],
      },
      // Billing Period
      {
        path: "/periodos-facturacion",
        name: "billing-periods",
        component: () => import("../views/billing_period/BillingPeriod.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/crear-periodo-facturacion",
        name: "create-billing-period",
        component: () =>
          import("../views/billing_period/CreateBillingPeriod.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/update-billing-period/:id",
        name: "update-billing-period",
        component: () =>
          import("../views/billing_period/UpdateBillingPeriod.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      // Service
      {
        path: "/services",
        name: "services",
        component: () => import("../views/service/Service.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/create-service",
        name: "create-service",
        component: () => import("../views/service/CreateService.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/update-service/:id",
        name: "update-service",
        component: () => import("../views/service/UpdateService.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      // Planes
      {
        path: "/planes",
        name: "plans",
        component: () => import("../views/plan/Plan.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/crear-plan",
        name: "create-plan",
        component: () => import("../views/plan/CreatePlan.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/actualizar-plan/:id",
        name: "update-plan",
        component: () => import("../views/plan/UpdatePlan.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      //Pagos
      {
        path: "/pagos",
        name: "pays",
        component: () => import("../views/payment/Pay.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      // Deudas
      {
        path: "/deudas",
        name: "debts",
        component: () => import("../views/debt/Debt.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/create-debt",
        name: "create-debt",
        component: () => import("../views/debt/CreateDebt.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/update-debt/:id",
        name: "update-debt",
        component: () => import("../views/debt/UpdateDebt.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },

      {
        path: "/get-debts-by-rut",
        name: "get-debts-by-rut",
        component: () => import("../views/debt/GetDebtsByRut.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/my-debts/:id",
        name: "my-debts",
        component: () => import("../views/debt/MyDebts.vue"),
      },
      {
        path: "/my-payments/:id",
        name: "payments",
        component: () => import("../views/payment/MyPayments.vue"),
        meta: {
          requiresAuth: true,
          adminApp: false,
        },
      },
      {
        path: "/mis-contratos/:id",
        name: "my-contracts",
        component: () => import("../views/contract/MyContracts.vue"),
      },

      // Empresas
      {
        path: "/clientes",
        name: "customers",
        component: () => import("../views/customer/Customer.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },

      {
        path: "/update-customer/:id",
        name: "update-customer",
        component: () => import("../views/customer/UpdateCustomer.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },

      {
        path: "/contratos",
        name: "contracts",
        component: () => import("../views/contract/Contract.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/crear-contrato",
        name: "create-contract",
        component: () => import("../views/contract/CreateContract.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
      {
        path: "/create-contract/:id",
        name: "create-contract-by-customer",
        component: () => import("../views/contract/CreateContractCustomer.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },

      // Empresas
      /* {
         path: '/contracts',
         name: 'contracts',
         component: () => import('../views/contract/Contract.vue')
       },
      
      
       {
         path: '/create-contract/:id',
         name: 'create-contract-by-customer',
         component: () => import('../views/contract/CreateContractCustomer.vue')
       },
       */
      {
        path: "/update-contract/:id",
        name: "update-contract",
        component: () => import("../views/contract/UpdateContract.vue"),
      },

      // Landing
      {
        path: "/facturacion-electronica",
        name: "facturacion-electronica",
        component: () => import("../views/landing/ElectronicBilling.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/boleta-electronica",
        name: "boleta-electronica",
        component: () => import("../views/landing/ElectronicTicket.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/hosting",
        name: "hosting",
        component: () => import("../views/landing/Hosting.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/firma-electronica",
        name: "firma-electronica",
        component: () => import("../views/landing/ElectronicSignature.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      //Equipments
      {
        path: "/equipos",
        name: "equipments",
        component: () => import("../views/equipment/Equipment.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },

      {
        path: "/crear-cliente",
        name: "create-customer",
        component: () => import("../views/customer/CreateCustomer.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/listar-equipos",
        name: "view-equipments",
        component: () => import("../views/equipment/Equipments.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/crear-equipos",
        name: "create-equipments",
        component: () => import("../views/equipment/CreateEquipments.vue"),
      },
      {
        path: "/actualizar-equipos/:id",
        name: "update-equipments",
        component: () => import("../views/equipment/UpdateEquipments.vue"),
      },

      /* {
         path: '/facturacion-electronica',
         name: 'facturacion-electronica',
         component: () => import('../views/ElectronicTicket.vue')
       },*/
      // Landing
      {
        path: "/login",
        name: "login",
        component: () => import("../views/login/Login.vue"),
        meta: {
          requiresAuth: false,
          adminApp: false,
        },
      },
      {
        path: "/preguntas-frecuentes",
        name: "faqs",
        component: () => import("../views/Faqs.vue"),
      },
      {
        path: "/nosotros",
        name: "us",
        component: () => import("../views/Us.vue"),
      },
      {
        path: "/contacto",
        name: "contact",
        component: () => import("../views/Contact.vue"),
      },

      {
        path: "/index",
        name: "index",
        component: () => import("../views/landing/index_page.vue"),
      },
      {
        path: "/admin",
        name: "dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          adminApp: true,
        },
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const userType = auth.getUserType();
  console.log(
    to.matched.some((route) => route.meta.requiresAuth) + " " + userType
  );
  if (to.matched.length) {
    if (
      to.matched.some((route) => route.meta.requiresAuth || route.meta.adminApp)
    ) {
      if (userType === "user") {
        next();
      } else if (
        userType === "admin" &&
        to.matched.some((route) => route.meta.adminApp)
      ) {
        next();
      } else {
        next({ name: "login" });
        // next('/')
      }
    } else {
      if (to.path === "/" && userType === "admin") {
        next({ name: "dashboard" });
      } else if (to.path === "/" && userType === "user") {
        
        next({ name: "my-debts", params: { id: auth.getUserId()  } });
      } else if (to.path === "/") {
        next({ name: "home" });
      } else {
        next();
      }
    }
  } else {
    next(to);
  }
});

//export default router

export default router;
