<template>
  <div>
    <v-row class="pt-10" style="background-color: #eeeeee">
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" mx="12" md="6">
        <div class="text-left ml-2 mr-2">
          <div class="text-overline text--grey-lighten-1">
            Bienvenido a Sipymex
          </div>
          <p class="text-h3 mt-4 font-weight-bold">
            En <label style="color: #179db0">Sipymex</label> sabemos <br />
            que su tiempo es lo más valioso
          </p>

          <p class="text-justify text-left text--secondary text-h6 pt-4">
            Ofrecemos soluciones integrales que contribuyen a una mayor<br />
            eficiencia y eficacia en la gestión en su negocio
          </p>

          <v-btn
            color="primary"
            elevation="3"
            class="mt-4"
            to="/boleta-electronica"
            outlined
          >
            <v-icon>mdi-store-search</v-icon>Saber más
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="0" xs="1" sm="1" class=""></v-col>
      <v-col cols="12" md="4" xs="10" sm="10" class="">
        <v-carousel
          hide-delimiter-background
          :show-arrows="false"
          hide-delimiters
          cycle
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            transition="fade-transition"
          >
            <v-img :src="item.src" width="400" />
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="0" xs="1" sm="1" class=""></v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" md="12">
        <div class="text-center">
          <div class="text-overline text--grey-lighten-1">Nuestros</div>
          <p class="text-h2 font-weight-bold" style="color: #179db0">
            Servicios
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <v-row class="">
          <v-col cols="12" md="4" v-for="(service, i) in services" :key="i">
            <v-card elevation="5" class="mx-auto" max-width="380" height="100%">
              <v-img :src="service.image"> </v-img>
              <div style="text-align: center">
                <div class="text-h6" color="#179db0">
                  {{ service.name }}
                </div>
              </div>
              <v-card-subtitle> {{ service.abstrac }} </v-card-subtitle>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :to="service.to"> Ver planes </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" md="12">
        <div class="text-center">
          <p class="text-h2 font-weight-bold" style="color: #179db0">
            ¿Por qué elegirnos?
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" md="1"></v-col>
          <v-col cols="12" md="3">
            <v-img src="../../../src/assets/elegir.png"></v-img>
          </v-col>
          <v-col cols="12" md="8">
            <v-card-text class="py-0">
              <v-timeline align-top dense>
                <v-timeline-item color="primary" small>
                  <v-row class="pt-1">
                    <v-col>
                      <strong>Entendemos sus inquietudes.</strong>
                      <div class="text-caption">
                        Estamos para apoyarlo en las necesidades de su negocio.
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item color="cyan" small>
                  <v-row class="pt-1">
                    <v-col>
                      <strong>Brindaremos asesoría personalizada.</strong>
                      <div class="text-caption">
                        Cada vez que lo necesite y acción oportuna ante sus
                        requerimientos para que su negocio siga en marcha.
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item color="orange" small>
                  <v-row class="pt-1">
                    <v-col>
                      <strong>Nuestra filosofía es Pyme para Pymes.</strong>
                      <div class="text-caption">
                        Buscamos mantener precios competitivos en nuestros
                        servicios para ser los mejores aliados en su
                        emprendimiento.
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item color="red" small>
                  <v-row class="pt-1">
                    <v-col>
                      <strong>A la vanguardia en tecnología.</strong>
                      <div class="text-caption">
                        Nos mantenemos en constante búsqueda de nuevas
                        herramientas para potenciar a la pequeña y mediana
                        empresa.
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1"> </v-col>
    </v-row>

    <v-row dense class="pt-10">
      <v-col cols="12">
        <v-card color="#000000" dark>
          <v-img
            class="white--text align-end"
            src="../../../src/assets/sunmi_banner.jpg"
          >
            <v-row dense class="pt-10 mb-10">
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" class="d-inline-block" md="6">
                <v-card-title>
                  <h3>Primera calidad en equipos</h3>
                </v-card-title>
                <v-card-subtitle
                  ><p>
                  Somos distribuidores autorizados en la X región de la marca
                  Sunmi, líder en el 
                  mercado de puntos de venta, asegurando su calidad y fiabilidad
                  de operación.</p>
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn text to="/equipos"> Ver Equipos </v-btn><br />
                </v-card-actions>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <br />
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <!--
    <v-row class="mt-10 mb-15">
      <v-col cols="12" md="12">
        <div class="text-center">
          <div class="text-overline text--grey-lighten-1">
            ¿Necesitas una página web?
          </div>
          <p class="text-h2 font-weight-bold" style="color: #0c71af">
            Nuestros planes de Hosting incluyen:
          </p>
        </div>
      </v-col>

      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <v-row>
          <v-col v-for="(item, i) in hosting" :key="i" cols="12" md="4">
            <v-card :color="item.color" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>

                  <v-card-subtitle v-text="item.description"></v-card-subtitle>

                  <v-card-actions>
                    <v-btn class="ml-2 mt-5" :to="item.to" outlined small>
                      Ver Planes
                    </v-btn>
                  </v-card-actions>
                </div>

                <v-avatar size="125" tile>
                  <v-img :src="item.src"></v-img>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1"></v-col>
    </v-row>
-->
    <v-row class="mt-10 mb-15">
      <v-col cols="12" md="12">
        <div class="text-center">
          <div class="text-overline text--grey-lighten-1">
            Servicio de boleta electrónica
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <p class="text-h2 text-center font-weight-bold" style="color: #0c71af">
          Nuestros planes de boleta electrónica incluyen:
        </p>
        <v-row>
          <v-col
            v-for="(item, i) in electronic_ticket"
            :key="i"
            cols="12"
            md="4"
          >
       
            <v-card :color="item.color" dark height="100%">

                  <v-card-subtitle> 
                    
            <v-row>
              <v-col cols="8" md="8" xs=" " sm="8">   <p class="text-overline "> {{item.title}}</p>
                  <p>{{item.description}}</p></v-col> 
              <v-col cols="2" md="2" xs="2" sm="2"><v-avatar class="d-inline-block" size="100" tile>
                  <v-img :src="item.src"></v-img>
                </v-avatar></v-col> 
            </v-row>
                  
          </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="1"> </v-col>
    </v-row>

    <v-row class="mt-10 mb-15">
      <v-col cols="12" md="12">
        <div class="text-center">
          <div class="text-overline text--grey-lighten-1">
            ¿Firma electrónica?
          </div>
          <p class="text-h2 font-weight-bold" style="color: #0c71af">
            Adquiere tu firma electrónica
          </p>
        </div>
      </v-col>

      <v-col cols="12" md="1"></v-col>
      <v-col cols="12" md="10">
        <v-row>
          <v-col v-for="(item, i) in firma" :key="i" cols="12" md="12">
            <v-card :color="item.color" dark>
            
                              <v-card-subtitle>

                    <v-row>
              <v-col cols="12" md="10" xs="12" sm="9">   <p class="text-overline "> {{item.title}}</p>
                <p>{{ item.description }} <br />{{ item.description2 }}</p></v-col> 

              <v-col cols="2" md="2" xs="2" sm="2"><v-avatar class="d-inline-block" size="150" tile>
                  <v-img :src="item.src"></v-img>
                </v-avatar></v-col> 
            </v-row>
                   
                    </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      class="ml-2 mt-5"
                      to="/firma-electronica"
                      outlined
                      small
                    >
                      Ir a Firmas
                    </v-btn>
                  </v-card-actions>
               
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>
<script>
import auth from "@/logic/auth";

export default {
  data() {
    return {
      items: [
        {
          src: require("../../../src/assets/slider/1.png"),
        },
        {
          src: require("../../../src/assets/slider/2.png"),
        },
        {
          src: require("../../../src/assets/slider/3.png"),
        },
        {
          src: require("../../../src/assets/slider/4.png"),
        },
        {
          src: require("../../../src/assets/slider/5.png"),
        },
      ],

      icons: ["mdi-instagram", "mdi-facebook"],

      padless: true,
      show: false,
      services: [
        {
          name: "Boleta electrónica",
          abstrac:
            "Pensado para negocios que requieran de una forma sencilla, cómoda y eficaz para emitir boletas electrónica.",
          description: "Descripcion",
          icon: "mdi-ticket-confirmation",
          image: require("../../../src/assets/boleta1.png"),
          to: "/boleta-electronica",
        },
        {
          name: "Facturación electrónica",
          abstrac:
            "Lleva tu negocio al siguiente nivel con nuestros planes de facturación electrónica.     ",
          description:
            "Lleva tu negocio al siguiente nivel con nuestros planes de facturación electrónica.      ",
          icon: "mdi-file-document",
          image: require("../../../src/assets/factura1.png"),
          to: "/facturacion-electronica",
        },
        {
          name: "Firma electrónica",
          abstrac:
            "Adquiere aquí tu firma electrónica indispensable para la emisión de documentos tributarios electrónicos.",
          description: "Descripcion",
          icon: "mdi-draw-pen",
          image: require("../../../src/assets/firma1.png"),
          to: "/firma-electronica",
        },
        /* {
          name: "Hosting 1",
          abstrac:
            "Planes de hosting asequibles para pequeñas y medianas empresas que deseen incursionar en la web.",
          description: "Descripcion",
          icon: "mdi-file-cloud",
          image: require("../../../src/assets/hosting1.png"),
          to: "/hosting",
        },*/
      ],
      colors: ["primary", "warning", "success"],
      slides: ["Boleta electronica ¿Su negocio está preparado?"],
      images: [
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
      ],

      firma: [
        {
          color: "#0c71af",
          src: require("../../../src/assets/efirma.png"),
          title: "Firma electrónica",
          description:
            "Herramienta indispensable para la emisión de documentos electrónicos y firma de documentos varios.",

          description2:
            " Si no cuenta con certificado digital o este ha vencido, compra aquí desde $19.000 Iva inc.",
        },
      ],

      hosting: [
        {
          color: "#0c71af",
          src: require("../../../src/assets/hosting1.png"),
          title: "Almacenamiento",
          description: "Espacio SSD ilimitado.",
          to: "/hosting",
        },
        {
          color: "#0c71af",
          src: require("../../../src/assets/hosting2.png"),
          title: "Bases de Datos",
          description: "Bases de Datos Ilimitadas",
          to: "/hosting",
        },
        {
          color: "#0c71af",
          src: require("../../../src/assets/hosting3.png"),
          title: "Dominio",
          description: "Registro de Dominio Web Gratuito",
          to: "/hosting",
        },
      ],
      electronic_ticket: [
        {
          color: "#0c71af",
          src: require("../../../src/assets/documentbussines.png"),
          title: "Emisión ilimitada",
          description: "Para vender sin límites ni costos adicionales",
          to: "/hosting",
        },
        {
          color: "#0c71af",
          src: require("../../../src/assets/invoicedocument.png"),
          title: "Plataforma de facturación",
          description:
            "Control de las ventas en todo lugar con todos los DTE incluidos",
          to: "/hosting",
        },
        {
          color: "#0c71af",
          src: require("../../../src/assets/offline11.png"),
          title: "Emisión Offline",
          description:
            "No es necesaria una conexión permanente a internet para emitir, pero sí será requerido para enviar información al SII",
          to: "/hosting",
        },
      ],
      plans: [
        {
          text: "Emisión de folios ilimitada",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Múltiples usuarios.",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Cuenta exclusiva para el contador.",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Volumen de venta de $10.000.000 mensuales* ",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Emisión sin conexión a internet* (debe conectarse al menos una vez al día para sincronizar documentos con el Sii)",
          icon: "mdi-check-decagram",
          color: "primary",
        },
      ],
    };
  },
  mounted() {
    if (auth.getUserType() == "admin") {
     // this.$router.push({ name: "dashboard" });
    }
  },
};
</script>