<template>
  <v-footer padless style="z-index: 0" class="">
    <v-card flat dark tile width="100%" class="text-center" color="#203a7c">
      <v-row justify="justify" class="mt-15 mb-10">
        <v-col cols="12" md="3" class="ml-15 pl-15">
          <p class="text-h6 text-left">Información de Contacto</p>
          <p class="text-h7 text-left">
            <v-icon class="">mdi-phone-in-talk</v-icon> +652 854091
          </p>
          <p class="text-h7 text-left">
            <v-icon class="">mdi-whatsapp</v-icon> +569 40566034
          </p>
          <p class="text-h7 text-left">
            <v-icon class="">mdi-email-edit</v-icon> ventas@sipymex.cl
          </p>
        </v-col>
        <v-col cols="12" md="4" class="ml-15 pl-15">
          <p class="text-h6 text-left">Horario de atención</p>
          <p class="text-h7 text-left">Lunes - Viernes</p>
          <p class="text-h7 text-left">09:00 a 15:30 hrs</p>
        </v-col>
        <v-col cols="12" md="3" class="pl-15 ml-15">
          <p class="text-h6 text-left">Sunmi</p>
          <p class="text-h7 text-left">Distribuidor oficial</p>
          <p class="text-h7 text-left">X región</p>
          <v-img
            class="text-h7 text-left"
            width="100px"
            src="@/assets/sunmi_white.png"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="2"> </v-col>
        <v-col cols="12" md="6">
          <v-card-text class="white--text">
            Servicios Informáticos Pymex SpA © 2022. Desarrollado por Klint
            Solutions SpA
          </v-card-text>
        </v-col>
        <v-col class="mt-2" cols="12" md="4">
          <div class="text-center">
            <v-dialog v-model="dialogs" 
            fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
            width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  white--text
                  text-caption
                  text-left
                  font-weight-light
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Términos de servicios
                </v-btn>
              </template>

              <v-card>
                <v-card-title
                  style="color: #203a7c"
                  class="light-blue darken-4 white--text"
                >
                  Términos de Servicios
                </v-card-title>

                <v-card-text class="text-justify">
                  <div class="mt-3">
                    <p>
                      TERMINOS Y CONDICIONES DE SERVICIO <br /><br />

                      A continuación, se describen los términos y condiciones
                      que rigen sobre el uso del servicio de boleta electrónica
                      y/o facturación electrónica provisto por SERVICIOS
                      INFORMÁTICOS PYMEX SPA bajo el rol único tributario (RUT)
                      77.180.056-4, teniendo por domicilio Benavente 575 OF. 202
                      Puerto Montt, Región de Los Lagos, en adelante SIPYMEX o
                      LA EMPRESA. Las marcas simple, punto de venta, pago,
                      openfactura y workspace son propiedad de Haulmer Inc. Bajo
                      el rol único tributario 76.795.561-8, estando SIPYMEX
                      autorizada a distribuirlas y dar soporte técnico bajo sus
                      condiciones en calidad de intermediario al cliente
                      final.<br />
                      Estos términos se aplicarán una vez las partes, SIPYMEX. y
                      el CLIENTE/ESTABLECIMIENTO, caracterizado e
                      individualizado en el ANEXO 1 de los presentes términos y
                      condiciones en su versión impresa y/o digital, firmen el
                      contrato por el que se da inicio a la relación comercial.
                      En este sentido, las partes se compromete en él a
                      garantizar su cumplimiento.
                      <br />
                      1. CONSIDERACIONES:<br />
                      1.1. Periodo: Se entenderá por periodo el intervalo de
                      tiempo en que la suscripción de servicio debe ser renovada
                      para dar su continuidad. Por efectos prácticos, los
                      periodos mensuales se considerarán en 30 (treinta) días
                      corridos, incluido el mes de febrero, en tanto los
                      periodos anuales se considerarán a 365 (trescientos
                      sesenta y cinco) días corridos desde el inicio del
                      mismo.<br />
                      1.2. Cliente: corresponde al cliente o establecimiento que
                      posee una relación comercial con SIPYMEX.<br />
                      1.3. Punto de venta o POS: Corresponde a la herramienta
                      física que permite utilizar el software e imprimir las
                      copias de documento físicas. (no debe confundirse con la
                      marca Punto de Venta utilizada por Haulmer Inc. Para dar
                      nombre a un software de emisión)<br />
                      1.4. Software: Corresponde a un programa informático
                      intangible encargado de la generación y gestión de
                      documentos tributarios electrónicos, así como pagos con
                      tarjetas (según sea el caso), En este apartado se
                      comprenden individual y en su conjunto las aplicaciones
                      Simple, Punto de Venta, Pago, Openfactura, Workspace,
                      entre otras aplicaciones futuras que puedan ser
                      implementadas por la empresa.<br />
                      1.5. Ficha de solicitud de servicio: Corresponde al
                      formulario rellenado al momento de la contratación del
                      servicio, el cual contiene la información necesaria para
                      realizar los trámites de certificación inicial,
                      información de contacto, así como el detalle de los
                      servicios y/o equipos adquiridos. Este documento posee un
                      número de folio único, el cual será asignado como el
                      identificador del cliente y de sus servicios asociados.
                      <br />
                      2. OBLIGACIONES<br />
                      2.1. Del CLIENTE/ESTABLECIMIENTO: <br />
                      Son obligaciones expresas del cliente/Establecimiento:<br />
                      • Usar el/los punto(s) de venta en los términos convenidos
                      a razón de su uso ordinario y en todas las ventas o
                      prestaciones que genere el cliente como emisor de boletas
                      electrónicas.<br />
                      • No dar otro uso al Terminal que no sea la emisión de
                      boletas electrónicas y aquellos descritos expresamente en
                      el contrato.<br />
                      • Proporcionar a SIPYMEX toda la documentación e
                      información necesaria para la certificación del servicio
                      ante el SII (servicio de impuestos internos).<br />
                      • Pagar a tiempo la mensualidad de servicio pactada de
                      acuerdo al tipo plan contratado y según la fecha de pago
                      acordada entre las partes, la cual será los días 5, 15 o
                      28 de cada mes en función del día más cercano a la fecha
                      de contratación, la cual será debidamente especificada en
                      la ficha de solicitud de servicio (ver pto. 1.5), así como
                      en la factura de entrega de equipos y/o servicios.<br />
                      • En el caso de que el CLIENTE/ESTABLECIMIENTO haga uso de
                      un chip de datos proporcionado por LA EMPRESA en función
                      del plan contratado, independientemente del proveedor de
                      señal de internet, cualquier actividad que no esté
                      directamente relacionada con la prestación del servicio,
                      tales como, compartir datos móviles, descarga de
                      películas, música, videos, imágenes, navegación de
                      internet, servicios de streaming o redes sociales, entre
                      otros, quedarán expresamente prohibidas. Para estos
                      efectos, se establece un límite de consumos de 2GB (dos
                      gigabytes) mensuales. Cualquier consumo por sobre el
                      límite hará presumir un uso inadecuado del chip y datos,
                      facultando a SIPYMEX a cobrar el sobreconsumo según la
                      tarifa vigente del proveedor de servicios de internet, o a
                      cancelar el servicio de pagos con tarjeta y/o boleta
                      electrónica unilateralmente sin derecho a indemnización
                      alguna.<br />
                      • Es de exclusiva responsabilidad del
                      CLIENTE/ESTABLECIMIENTO mantener vigente su certificado
                      digital o firma electrónica, pudiendo adquirirlo
                      indistintamente a través de SIPYMEX, o cualquier otra
                      empresa externa, siempre y cuando cumpla con los
                      requisitos de compatibilidad del software asociado al
                      servicio (formato .pfx o .p12). 2.2. De SIPYMEX:<br />
                      Son obligaciones expresas de SIPYMEX:<br />
                      • Realizar todos los trámites necesarios para la
                      habilitación del servicio ante el SII, siempre y cuando
                      estos estén dentro de sus facultades técnicas y con la
                      documentación provista por el CLIENTE/ESTABLECIMIENTO.<br />
                      • Otorgar bajo licencia de suscripción el software
                      necesario para la emisión de documentos tributarios
                      electrónicos, ya sea mediante las aplicaciones simple,
                      punto de venta, pago, openfactura o workspace, con sus
                      respectivas credenciales de acceso, individualmente,
                      algunas o todas en su conjunto según el tipo de plan
                      escogido por el CLIENTE/ESTABLECIMIENTO.<br />
                      • Responder asistencial y técnicamente las fallas que se
                      produzcan en el software proporcionado, o en los
                      terminales punto de venta adquiridos según su garantía
                      legal de 6 meses ante desperfectos de fabricación (excluye
                      daños ocasionados por el mal uso de los equipos, tales
                      como, caídas, manipulación de externos, exposición a
                      líquidos o solventes, etc.).<br />
                      • Resguardar bajo completa discreción la información que
                      el CLIENTE/ESTABLECIMIENTO proporcione, y solo podrá ser
                      utilizada por SIPYMEX para los efectos expresamente
                      detallados en los presentes términos y condiciones.<br />

                      3. EXCLUSIÓN DE RESPONSABILIDAD.<br /><br />
                      3.1. No forman parte de las responsabilidades de
                      SIPYMEX:<br />

                      - Los errores u omisiones cometidas por el
                      CLIENTE/ESTABLECIMIENTO cuando del uso del terminal y/o
                      softwares se trate, y los inconvenientes que estos puedan
                      causar.<br />
                      - El no cumplimiento de las obligaciones tributarias por
                      parte del CLIENTE/ESTABLECIMIENTO ante el SII (servicio de
                      impuestos internos)<br />
                      - Los inconvenientes, multas y/o sanciones impuestas por
                      el SII (servicio de impuestos internos) al
                      CLIENTE/ESTABLECIMENTO generadas por el incumplimiento de
                      la obligación de emisión de boleta electrónica (o
                      cualquier otro documento), además de cualquier otra
                      situación ocasionada por la suspensión de servicios por no
                      pago de la suscripción, o vencimiento de firmas
                      electrónicas.<br />
                      - Las declaraciones de impuestos, de renta o modelo de
                      emisión (en caso de operaciones con pagos con
                      tarjetas).<br />
                      - Los daños a los equipos atribuibles al usuario, o el
                      desgaste de sus componentes ocasionado por el uso normal
                      del mismo en el largo plazo.<br />

                      4. USO DE DATOS<br /><br />
                      4.1. SIPYMEX podrá hacer uso de los datos proporcionados
                      por el cliente únicamente para los siguientes efectos:<br />
                      - Certificación inicial ante el SII (servicio de impuestos
                      internos).<br />
                      - Labores de soporte técnico.<br />
                      - Contacto.<br />
                      - Timbraje y/o, de ser necesario, anulación masiva de
                      folios ante el SII (servicio de impuestos internos)<br />
                      - Facturación y cobranza.<br />
                      4.2. SIPYMEX mantendrá a resguardo el certificado digital
                      o firma electrónica del CLIENTE/ESTABLECIMIENTO a modo de
                      respaldo, pudiendo el cliente solicitar una copia en
                      cualquier momento mientras su servicio se encuentre
                      activo. Adicionalmente, podrá hacer uso de esta únicamente
                      para la firma de términos y condiciones de servicio,
                      mismos que serán informados oportunamente al
                      CLIENTE/ESTABLECIMIENTO. SIPYMEX, bajo ninguna
                      circunstancia, podrá utilizar la firma electrónica o
                      certificado digital para otro fin del que no se haya
                      descrito en el presente inciso.<br />
                      4.3. Una vez finalizada la relación comercial con el
                      CLIENTE/ESTABLECIMIENTO, SIPYMEX eliminará permanentemente
                      todos los datos proporcionados, incluida la firma
                      electrónica, en un plazo no mayor a 12 (doce) horas
                      hábiles. Es de exclusiva responsabilidad del cliente
                      solicitar los respaldos de los mismos antes de que estos
                      sean eliminados, no dando derecho a indemnización alguna
                      por la pérdida de información no respaldada.<br />

                      5. MORA<br /><br />
                      5.1. Se considera mora el no cumplimiento del pago de la
                      suscripción en las fechas acordadas.<br />
                      5.2. El plazo de pago será dentro de los 3 días hábiles
                      siguientes a la fecha de vencimiento del periodo. Pasado
                      ese plazo, se enviarán 3 mensajes de aviso de pago
                      pendiente al correo electrónico del
                      CLIENTE/ESTABLECIMIENTO o cualquier otro medio de contacto
                      registrado en la ficha de solicitud de servicio (ver pto.
                      1.5), siendo el último mensaje un aviso de suspensión de
                      servicio, el cual indicará la fecha de corte según los 15
                      días corridos desde la fecha de vencimiento del periodo
                      vigente.<br />
                      5.3. De no efectuarse el pago del periodo transcurridos 15
                      días corridos desde la fecha de vencimiento, se suspenderá
                      el servicio.<br />
                      5.4. La restitución del servicio tendrá un plazo de 3
                      horas hábiles desde verificado el pago del/los periodos
                      pendientes.<br />
                      5.5. Los pagos para efectos de restitución de servicio
                      realizados un día viernes después de las 12:00 horas,
                      fines de semana o festivos, serán tramitados al día hábil
                      siguiente. El tiempo en que el servicio no esté activo, no
                      dará derecho a ningún tipo de indemnización o descuento
                      del valor de mensualidad o anualidad según el plan
                      contratado.<br />
                      5.6. Los pagos realizados mediante transferencia bancaria
                      o depósitos en caja vecina, deberán ser debidamente
                      acreditados con el comprobante de transacción para hacer
                      efectiva la restitución de servicio. Para estos efectos,
                      LA EMPRESA ha dispuesto canales de comunicación directos
                      especificados en el punto 8.<br />
                      5.7. La restitución de servicio tendrá un costo de $3.000
                      (tres mil pesos) IVA incluido si el pago del periodo ha
                      sido después de 30 días corridos desde la fecha de
                      vencimiento del mismo, el cual será cobrado en el
                      siguiente periodo a facturar.<br />
                      5.8. El no pago de periodos pendientes facultará a SIPYMEX
                      a tomar acciones legales de ser necesario.<br />
                      5.9. SIPYMEX se reserva bajo su exclusivo criterio el
                      derecho de suspender o no los servicios del
                      CLIENTE/ESTABLECIMIENTO si este cuenta con morosidad
                      vigente.<br />

                      6. SUSPENSIÓN TEMPORAL.<br /><br />
                      6.1. El CLIENTE/ESTABLECIMIENTO podrá solicitar la
                      suspensión temporal de su servicio, siempre y cuando posea
                      suscripción mensual, ante eventualidades esporádicas aquí
                      detalladas:<br />

                      - Cierre temporal del local superior a 60 (sesenta) días
                      por remodelaciones, vacaciones o situaciones
                      personales.<br />
                      - Reestructuración comercial que requiera un cese en la
                      actividad en un plazo superior a 60 (sesenta) días.<br />
                      6.2. Para hacer válida esta cláusula, el
                      CLIENTE/ESTABLECIMIENTO deberá informar a SIPYMEX con al
                      menos 15 (quince) días de anticipación al próximo
                      vencimiento del periodo vigente mediante los canales de
                      comunicación habilitados por la empresa.<br />
                      6.3. Al solicitar la suspensión temporal de servicio, se
                      exime al CLIENTE/ESTABLECIMIENTO del pago de los periodos
                      mensuales comprendidos durante la suspensión.<br />
                      6.4. Para restituir el servicio, deberá hacer el pago del
                      periodo en que retomará su actividad por adelantado.<br />

                      7. SOPORTE<br /><br />
                      7.1. Se entiende como soporte técnico todas las
                      intervenciones realizadas por la empresa para garantizar
                      la continuidad del servicio.<br />
                      7.2. El CLIENTE / ESTABLECIMIENTO podrá comunicarse con
                      soporte técnico de SIPYMEX para resolver cualquier tipo de
                      inquietud respecto al servicio en los horarios
                      establecidos de 09:00 a 18:00 de lunes a viernes (excepto
                      feriados y festivos).<br />
                      7.3. Ante eventuales intervenciones al punto de venta que
                      requieran de su ingreso a taller, y cuya reparación
                      requiera de más de un día hábil, SIPYMEX podrá disponer
                      para el CLIENTE / ESTABLECIMIENTO, un punto de venta de
                      reemplazo temporal, que no podrá ser otorgado por más de
                      30 días corridos desde su entrega, prorrogable por un
                      máximo de 10 días corridos de ser necesario. Transcurrido
                      ese tiempo, el punto de venta del CLIENTE /
                      ESTABLECIMIENTO será considerado como irreparable, en tal
                      caso, SIPYMEX pone a disposición las siguientes opciones
                      de restitución, cuyos costos asociados correrán por cuenta
                      del CLIENTE / ESTABLECIMIENTO.<br />

                      - Sustitución por equipo totalmente nuevo<br />
                      - Sustitución por equipo reacondicionado<br />
                      - Convenio de arriendo temporal o permanente.<br />
                      7.4. Los costos asociados a los repuestos requeridos para
                      una reparación, así como la mano de obra, correrán por
                      parte del CLIENTE / ESTABLECIMIENTO, a no ser que mantenga
                      garantía vigente.<br />

                      8. CANALES DE COMUNICACIÓN<br /><br />
                      8.1. Correo electrónico:<br />
                      - ventas@sipymex.cl (para ventas y/o envío de
                      documentación para certificación)<br />
                      - info@sipymex.cl (para información y/o envío de
                      documentación varia)<br />
                      - soporte@sipymex.cl (para soporte técnico)<br />
                      - pagos@sipymex.cl (para notificaciones de cobranza, envío
                      de comprobantes, facturas y consultas relacionadas)<br />

                      8.2. Teléfono:<br />
                      - +569 40566034 (para ventas, certificaciones y envío de
                      comprobantes de pago mediante Whatsapp)<br />
                      - +569 99420960 (para soporte técnico telefónico o via
                      Whatsapp)<br />
                      - +56 652 854091 (central telefónica)<br />

                      9. VIGENCIA<br />
                      9.1. Los presentes términos y condiciones tendrán una
                      vigencia indefinida desde el momento de la firma del
                      mismo, pudiendo sufrir modificaciones las cuales serán
                      debidamente informadas al CLIENTE/ESTABLECIMIENTO.<br />
                      9.2. Sin perjuicio de lo anterior, las partes podrán poner
                      fin a la relación comercial establecida en cualquier
                      momento, debiendo dar aviso a la otra parte con al menos
                      30 días de anticipación, sin la obligación de explicar
                      motivos.<br />

                      10. ACEPTACIÓN DEL CONTRATO<br />
                      10.1. Con la finalidad de conceder un mejor servicio y la
                      pronta puesta en marcha de los productos contratados,
                      luego de ser rellenada la ficha de solicitud de servicio y
                      pagados los montos de habilitación, SIPYMEX enviará vía
                      correo electrónico al CLIENTE/ESTABLECIMIENTO una copia
                      del presente contrato firmada con su certificado
                      digital.<br />
                      10.2. En el caso de que el CLIENTE/ESTABLECIMIENTO no
                      estuviere de acuerdo, contará con 5 (cinco) días hábiles
                      para solicitar la entrega de una copia física del mismo,
                      así como su correspondiente firma en manuscrito.<br />
                      10.3. La omisión de respuesta pasados los 5 (cinco) días
                      hábiles del envío del presente contrato, se interpretará
                      como la plena aceptación de los términos y condiciones
                      aquí contenidos.<br />
                      10.4. En el caso de que el cliente no acepte los términos
                      y condiciones, tendrá un plazo de 2 días hábiles para
                      manifestar su disconformidad, no siendo reembolsables los
                      importes asociados a la habilitación de servicio. En tal
                      caso SIPYMEX enviará la factura por los importes cobrados
                      junto con el certificado digital adquirido durante el
                      proceso; SIPYMEX eliminará permanentemente todos los datos
                      y documentos proporcionados por el cliente en un plazo
                      máximo de 12 horas hábiles tras la recepción de la
                      solicitud.<br />
                    </p>
                  </div>
                  <div class="my-3">
                    a) Si se declara la quiebra de una de las partes, o si una
                    de ellas cayere en cesación de pagos o notoria
                    insolvencia;<br />
                    b) Variación sustancial, a criterio exclusivo de SIPYMEX, de
                    las condiciones que se tuvieron en vista y en consecuencia
                    originaron y motivaron este Contrato;<br />
                    c) Si una de las partes es disuelta y/o entra en proceso de
                    liquidación; y, <br />
                    d) Si una de las partes transfiere a un tercero la totalidad
                    o más de las tres cuartas partes de sus bienes necesarios
                    para el desarrollo de su giro;<br />
                    e) Variación sustancial, de las condiciones del servicio,
                    que originaron y motivaron este Contrato.
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogs = false">
                    Acepto
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog"
             fullscreen
      hide-overlay
      transition="dialog-bottom-transition" width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  white--text
                  text-caption
                  text-left
                  font-weight-light
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Política de Privacidad
                </v-btn>
              </template>

              <v-card>
                <v-card-title
                  style="color: #203a7c"
                  class="light-blue darken-4 white--text"
                >
                  Política de Privacidad
                </v-card-title>

                <v-card-text class="text-justify">
                  <div class="mt-3">
                    
                     
<p>I. POL&Iacute;TICA DE PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS PERSONALES</p>
<p>El sitio web <a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a>, en adelante &quot;SIPYMEX SPA&quot;, &quot;<a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a>&quot; o &quot;el sitio web&quot; indistintamente, pone en conocimiento de las personas que hagan uso del mismo, en adelante &quot;personas usuarias&quot;, la presente pol&iacute;tica de privacidad y protecci&oacute;n de los datos personales.</p>
<p>Esta pol&iacute;tica de privacidad y protecci&oacute;n de los datos personales forma parte de los T&eacute;rminos y Condiciones Generales de Uso del sitio web <a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a>. Para mayor informaci&oacute;n, revise los T&eacute;rminos y Condiciones Generales de Uso contenidos en el sitio web.</p>
<p>La lectura de la misma le permitir&aacute; a las personas usuarias conocer el modo en que SIPYMEX SPA recolecta, trata y protege de sus datos personales.</p>
<p>El acceso, uso y permanencia en el sitio web <a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a> implica la aceptaci&oacute;n de la presente pol&iacute;tica de privacidad.</p>
<p>De particular importancia resultan la aplicaci&oacute;n de la Ley N&deg; 19.628 de Protecci&oacute;n de Datos Personales y la Ley N&deg; 19.496 sobre Derechos del Consumidor.</p>
<p>Esta pol&iacute;tica, en lo que no contraviene la legislaci&oacute;n chilena, est&aacute; adaptada al Reglamento Europeo de Protecci&oacute;n de Datos (RGPD).</p>
<p>1. Definiciones</p>
<p>a. Almacenamiento de datos: conservaci&oacute;n o custodia de datos en un registro, banco o base de datos.</p>
<p>b. Dato estad&iacute;stico: aquel que en su origen, o como consecuencia de su tratamiento, no puede ser asociado a un titular identificado o identificable.</p>
<p>c. Datos de car&aacute;cter personal o datos personales: aquellos relativos a cualquier informaci&oacute;n concerniente a personas naturales, identificadas o identificables.</p>
<p>d. Datos sensibles: aquellos datos personales que se refieren a las caracter&iacute;sticas f&iacute;sicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los h&aacute;bitos personales, el origen racial, las ideolog&iacute;as y opiniones pol&iacute;ticas, las creencias o convicciones religiosas, los estados de salud f&iacute;sicos o ps&iacute;quicos y la vida sexual.</p>
<p>e. Registro, banco o base de datos: conjunto organizado de datos de car&aacute;cter personal, sea automatizado o no y cualquiera sea la forma o modalidad de su creaci&oacute;n u organizaci&oacute;n, que permita relacionar los datos entre s&iacute;, as&iacute; como realizar todo tipo de tratamiento de datos.</p>
<p>f. Responsable del registro, banco o base de datos: la persona natural o jur&iacute;dica a quien compete las decisiones relacionadas con el tratamiento de los datos de car&aacute;cter personal, tambi&eacute;n conocido como responsable del tratamiento de datos personales.</p>
<p>g. Titular de los datos: persona natural a la que se refieren los datos de car&aacute;cter personal.</p>
<p>h. Tratamiento de datos: cualquier operaci&oacute;n o complejo de operaciones o procedimientos t&eacute;cnicos, de car&aacute;cter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de car&aacute;cter personal, o utilizarlos en cualquier otra forma.</p>
<p>i. Cliente: persona natural o jur&iacute;dica que mantiene un v&iacute;nculo comercial con SIPYMEX SPA mediante la adquisici&oacute;n de productos o suscripci&oacute;n a servicios.&nbsp;</p>
<p>2. Principios aplicables al tratamiento de los datos personales</p>
<p>El tratamiento de los datos personales de las personas usuarias se someter&aacute; a los siguiente principios:</p>
<p>a. Principio de licitud, lealtad y transparencia: se requerir&aacute; en todo momento el consentimiento de la persona usuaria previa informaci&oacute;n completamente transparente de los fines para los cuales se recogen los datos personales.</p>
<p>b. Principio de limitaci&oacute;n de la finalidad: los datos personales ser&aacute;n recogidos con fines determinados, expl&iacute;citos y leg&iacute;timos.</p>
<p>c. Principio de minimizaci&oacute;n de datos: los datos personales recogidos ser&aacute;n &uacute;nicamente los estrictamente necesarios en relaci&oacute;n con los fines para los que son tratados.</p>
<p>d. Principio de exactitud: los datos personales deben ser exactos y ser&aacute;n siempre actualizados.</p>
<p>e. Principio de limitaci&oacute;n del plazo de conservaci&oacute;n: los datos personales solo ser&aacute;n mantenidos de forma que se permita la identificaci&oacute;n de la persona usuaria durante el tiempo necesario para los fines de su tratamiento.</p>
<p>f. Principio de integridad y confidencialidad: los datos personales ser&aacute;n tratados de manera que se garantice su seguridad y confidencialidad.</p>
<p>g. Principio de responsabilidad proactiva: la persona responsable del tratamiento ser&aacute; responsable de asegurar que los principios anteriores se cumplan.</p>
<p>3. Responsable del registro, banco o base de datos</p>
<p>La persona responsable del tratamiento de los datos personales recogidos a trav&eacute;s del sitio web SIPYMEX SPA es SERVICIOS INFORM&Aacute;TICOS PYMEX SPA, Rol &Uacute;nico Tributario N&deg; 77.180.056-4, representada por BENJAM&Iacute;N ANDR&Eacute;S BRITO OYARZO, c&eacute;dula nacional de identidad N&deg; 20.292.842-0, en adelante, la responsable del tratamiento.</p>
<p>Los datos para tomar contacto con la persona responsable son:</p>
<p>Correo electr&oacute;nico: <a href="mailto:info@sipymex.cl">info@sipymex.cl</a>&nbsp; &nbsp;&nbsp;&nbsp;</p>
<p>Direcci&oacute;n: BENAVENTE 575 OF. 202</p>
<p>4. Recolecci&oacute;n y registro de datos de car&aacute;cter personal y finalidad de su tratamiento</p>
<p>Los datos personales obtenidos por SIPYMEX SPA mediante los formularios extendidos en sus p&aacute;ginas quedar&aacute;n incorporados y ser&aacute;n tratados en nuestras bases de datos con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre SIPYMEX SPA y las personas usuarias, o para el mantenimiento de la relaci&oacute;n que se establezca en los formularios que &eacute;stas rellenen, o para atender una solicitud o consulta de las mismas</p>
<p>En concreto, los datos de las personas usuarias ser&aacute;n obtenidos por SIPYMEX SPA a trav&eacute;s de la o las siguientes acciones:</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Formulario de contacto.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Formulario de contrataci&oacute;n de servicios.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Formulario de adquisici&oacute;n de equipos u otros productos f&iacute;sicos.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Correo electr&oacute;nico.</p>
<p>Adicionalmente, SIPYMEX SPA podr&aacute; incorporar a sus bases de datos informaci&oacute;n obtenida mediante formularios de solicitud de servicios manuales, mediante tel&eacute;fono, o canales de comunicaci&oacute;n de mensajer&iacute;a instant&aacute;nea (Whatsapp, Telegram o similares), siempre bajo expresa solicitud del cliente.</p>
<p>5. Uso de datos</p>
<p>SIPYMEX SPA utilizar&aacute; los datos &uacute;nicamente con los siguientes prop&oacute;sitos:</p>
<p>a. &nbsp; &nbsp;Env&iacute;o de respuestas y actualizaciones:</p>
<p>SIPYMEX SPA utilizar&aacute; la informaci&oacute;n de contacto provista por el usuario o cliente con el fin de atender oportunamente sus requerimientos y/o consultas respecto a productos o servicios. SIPYMEX SPA podr&iacute;a compartir informaci&oacute;n de contacto o mensajes recibidos con sus socios comerciales, bajo expreso consentimiento del usuario / cliente, con el fin de dar pronta respuesta a sus requerimientos.</p>
<p>b. &nbsp; &nbsp;Facilitar el soporte, renovaci&oacute;n y adquisici&oacute;n de productos y/o servicios:</p>
<p>Es posible que SIPYMEX SPA utilice la informaci&oacute;n que el usuario / cliente expl&iacute;citamente haya proporcionado para facilitar la atenci&oacute;n a sus requerimientos relativos al soporte t&eacute;cnico post venta, renovaci&oacute;n y/o adquisici&oacute;n de productos o servicios.</p>
<p>c. &nbsp; &nbsp;Obligaci&oacute;n legal:</p>
<p>Si SIPYMEX SPA se ve en la obligaci&oacute;n de revelar informaci&oacute;n a un organismo estatal, juzgado competente, u otro similar para cumplir con la legislaci&oacute;n chilena, deber&aacute; proporcionar sin reparos toda la informaci&oacute;n que el organismo requiera.</p>
<p>d. &nbsp; &nbsp;Acciones de cobranza: Seg&uacute;n lo estipulado en los t&eacute;rminos y condiciones del servicio contratado, SIPYMEX SPA podr&aacute; compartir informaci&oacute;n de contacto con empresas externas dedicadas a acciones de cobranza en caso de que el cliente cuente con mora. Cada servicio posee sus t&eacute;rminos y condiciones propios, en ellos se especifica el procedimiento relativo a las acciones de cobranza.</p>
<p>6. Categor&iacute;a de datos personales</p>
<p>Las categor&iacute;as de datos que se tratan en SIPYMEX SPA son &uacute;nicamente datos identificativos. En ning&uacute;n caso se tratan categor&iacute;as de datos personales de car&aacute;cter sensibles, como el estado de salud de las personas o sus opiniones pol&iacute;ticas o creencias religiosas.</p>
<p>No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice o exista consentimiento de la persona titular de dichos datos.</p>
<p>7. Base legal para el tratamiento de los datos personales</p>
<p>El tratamiento de los datos personales solamente puede efectuarse cuando la ley lo autorice o el titular consienta expresamente en ello.</p>
<p>SIPYMEX SPA se compromete a recabar el consentimiento expreso, escrito y verificable de la persona usuaria respecto de los datos personales de los que es titular, para el tratamiento de dichos datos para uno o varios fines espec&iacute;ficos, debidamente informados.</p>
<p>Tambi&eacute;n se informar&aacute; de la posible comunicaci&oacute;n al p&uacute;blico de los datos almacenados y tratados.</p>
<p>No requiere autorizaci&oacute;n el tratamiento de datos personales que provengan o que se recolecten de fuentes accesibles al p&uacute;blico, cuando sean de car&aacute;cter econ&oacute;mico, financiero, bancario o comercial, se contengan en listados relativos a una categor&iacute;a de personas que se limiten a indicar antecedentes tales como la pertenencia del individuo a ese grupo, su profesi&oacute;n o actividad, sus t&iacute;tulos educativos, direcci&oacute;n o fecha de nacimiento, o sean necesarios para comunicaciones comerciales de respuesta directa o comercializaci&oacute;n o venta directa de bienes o servicios.</p>
<p>Tampoco requerir&aacute; de esta autorizaci&oacute;n el tratamiento de datos personales que realicen personas jur&iacute;dicas privadas para el uso exclusivo suyo, de sus asociados y de las entidades a que est&aacute;n afiliadas, con fines estad&iacute;sticos, de tarificaci&oacute;n u otros de beneficio general de aqu&eacute;llos.</p>
<p>Los datos personales deben utilizarse solamente para los fines para los cuales hubieren sido recolectados, salvo que provengan o se hayan recolectado de fuentes accesibles al p&uacute;blico.</p>
<p>No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice, exista consentimiento del titular o sean datos necesarios para la determinaci&oacute;n u otorgamiento de beneficios de salud que correspondan a sus titulares.</p>
<p>La persona usuaria tendr&aacute; derecho a retirar su consentimiento en cualquier momento. Ser&aacute; tan f&aacute;cil retirar el consentimiento como darlo. Como regla general, el retiro del consentimiento no condicionar&aacute; el uso del sitio web.</p>
<p>En las ocasiones en las que la persona usuaria deba o pueda facilitar sus datos a trav&eacute;s de formularios para realizar consultas, solicitar informaci&oacute;n o por motivos relacionados con el contenido del sitio web, se le informar&aacute; en caso de que la cumplimentaci&oacute;n de alguno de ellos sea obligatorio debido a que los mismos sean imprescindibles para el correcto desarrollo de la operaci&oacute;n realizada.</p>
<p>8. Per&iacute;odo de retenci&oacute;n de los datos personales</p>
<p>Los datos personales solamente ser&aacute;n retenidos durante el tiempo m&iacute;nimo necesario para los fines de su tratamiento y, en todo caso, &uacute;nicamente durante el siguiente plazo: 180 d&iacute;as, o hasta que la persona usuaria solicite su supresi&oacute;n. Esto aplica &uacute;nicamente a datos temporales que no constituyan la contrataci&oacute;n de un servicio; los datos proporcionados con el fin de dar operatividad a un servicio se mantendr&aacute;n almacenados indefinidamente mientras el mismo se encuentre activo. Una vez que un servicio sea dado de baja, los datos asociados al mismo se mantendr&aacute;n durante el tiempo m&iacute;nimo requerido para las tramitaciones de t&eacute;rmino de servicio, y, en todo caso, &uacute;nicamente durante el siguiente plazo: 15 d&iacute;as h&aacute;biles a contar de la fecha de solicitud de t&eacute;rmino del servicio.</p>
<p>9. Destinatarios de los datos personales</p>
<p>Los datos personales de las personas usuarias podr&aacute;n ser compartidos con terceras personas, para lo cual se deber&aacute; dejar constancia en esta pol&iacute;tica de privacidad, efectuando su actualizaci&oacute;n, si correspondiere, de la individualizaci&oacute;n de la persona destinataria, el motivo y el prop&oacute;sito de la transmisi&oacute;n de datos hacia ella, y el tipo de datos que se transmiten.</p>
<p>La persona destinataria solamente podr&aacute; utilizar los datos personales que se le transmitan para los fines que motivaron la transmisi&oacute;n.</p>
<p>En caso de que la persona responsable del tratamiento tenga la intenci&oacute;n de transferir datos personales a un tercer pa&iacute;s u organizaci&oacute;n internacional, en el momento en que se obtengan los datos personales se informar&aacute; a las personas usuarias acerca del tercer pa&iacute;s u organizaci&oacute;n internacional al cual se tiene la intenci&oacute;n de transferir los datos, salvo que dicha transmisi&oacute;n sea en cumplimiento de lo dispuesto en los tratados y convenios vigentes.</p>
<p>10. Datos personales de menores de edad</p>
<p>Solamente las personas mayores de 14 a&ntilde;os podr&aacute;n otorgar su consentimiento para el tratamiento de sus datos personales de forma l&iacute;cita por SIPYMEX SPA.</p>
<p>Si se trata de una persona menor de 14 a&ntilde;os ser&aacute; necesario el consentimiento de los padres o representantes legales o por quien tiene a su cargo el cuidado personal del ni&ntilde;o o ni&ntilde;a, salvo que expresamente lo autorice o mandate la ley.</p>
<p>Los datos sensibles de las personas adolescentes menores de 16 a&ntilde;os, solamente se podr&aacute;n tratar con el consentimiento otorgado por sus padres o representantes legales o quien tiene a su cargo el cuidado personal del menor, salvo que expresamente lo autorice o mandate la ley.</p>
<p>11. Secreto y seguridad de los datos personales</p>
<p>SIPYMEX SPA se compromete a adoptar las medidas t&eacute;cnicas y organizativas necesarias, seg&uacute;n el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de car&aacute;cter personal y se evite la destrucci&oacute;n, p&eacute;rdida o alteraci&oacute;n accidental o il&iacute;cita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicaci&oacute;n o acceso no autorizado a dichos datos.</p>
<p>El sitio web <a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a> cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se transmiten de forma segura y confidencial, al ser esta transmisi&oacute;n entre el servidor y la persona usuaria, y en retroalimentaci&oacute;n, totalmente cifrada o encriptada.</p>
<p>Sin embargo, debido a que SIPYMEX SPA no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, la persona responsable del tratamiento se compromete a comunicar a las personas usuarias, sin dilaci&oacute;n indebida, la ocurrencia de cualquier violaci&oacute;n de la seguridad de los datos personas que sea probable que entra&ntilde;e un alto riesgo para los derechos y libertades de las personas f&iacute;sicas. Se entiende por violaci&oacute;n de la seguridad de los datos personales toda violaci&oacute;n de la seguridad que ocasione la destrucci&oacute;n, p&eacute;rdida o alteraci&oacute;n accidental o il&iacute;cita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicaci&oacute;n o acceso no autorizado a dichos datos.</p>
<p>Los datos personales ser&aacute;n tratados como confidenciales por la persona responsable del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligaci&oacute;n legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados y toda persona a la cual le haga accesible la informaci&oacute;n.</p>
<p>12. Derechos derivados del tratamiento de los datos personales</p>
<p>La persona usuaria tiene sobre SIPYMEX SPA y podr&aacute;, por tanto, ejercer frente a la persona responsable del tratamiento los siguientes derechos:</p>
<p>a. Derecho de acceso: es el derecho que tienen las personas usuarias a obtener confirmaci&oacute;n de si SIPYMEX SPA est&aacute; tratando o no sus datos personales y, en caso afirmativo, obtener informaci&oacute;n sobre sus datos concretos de car&aacute;cter personal y del tratamiento que SIPYMEX SPA haya realizado o realice, as&iacute; como, entre otras, de la informaci&oacute;n disponible sobre el origen de dichos datos y las personas destinatarias de las comunicaciones realizadas o previstas de los mismos.</p>
<p>b. Derecho de rectificaci&oacute;n: es el derecho de las personas usuarias a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.</p>
<p>c. Derecho de supresi&oacute;n (&quot;el derecho al olvido&quot;): Es el derecho de las personas usuarias, siempre que la legislaci&oacute;n vigente no establezca lo contrario, a obtener la supresi&oacute;n de sus datos personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; cuando la persona usuaria haya retirado su consentimiento al tratamiento y no exista otro motivo leg&iacute;timo para continuar con el mismo; cuando los datos personales hayan sido tratados il&iacute;citamente; cuando los datos personales deban suprimirse en cumplimiento de una obligaci&oacute;n legal.</p>
<p>Si los datos personales suprimidos o rectificados hubieren sido comunicados previamente a tercera personas determinadas o determinables, la persona responsable del tratamiento deber&aacute; avisarles a la brevedad posible la operaci&oacute;n efectuada. Si no fuese posible determinar las personas a quienes se les haya comunicado, pondr&aacute; un aviso que pueda ser de general conocimiento para quienes usen la informaci&oacute;n de la base datos.</p>
<p>No podr&aacute; pedirse la rectificaci&oacute;n, supresi&oacute;n o bloqueo de datos personales almacenados por mandato legal, fuera de los casos contemplados en la ley respectiva.</p>
<p>d. Derecho a la limitaci&oacute;n del tratamiento: es el derecho de las personas usuarias a limitar el tratamiento de sus datos personales. La persona usuaria tiene derecho a obtener la limitaci&oacute;n del tratamiento cuando impugne la exactitud de sus datos personales, el tratamiento sea il&iacute;cito, el responsable del tratamiento ya no necesite los datos personales, pero la persona usuaria los necesite para hacer reclamaciones, y cuando la persona usuaria se haya opuesto al tratamiento.</p>
<p>e. Derecho a la portabilidad de los datos: en caso que el tratamiento se efect&uacute;e por medios automatizados, la persona usuaria tendr&aacute; derecho a recibir de la persona responsable del tratamiento sus datos personales en un formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea t&eacute;cnicamente posible, la persona responsable del tratamiento transmitir&aacute; directamente los datos a esa otra responsable.</p>
<p>f. Derecho de oposici&oacute;n: es el derecho de las persona usuarias a que no se lleve a cabo el tratamiento de sus datos de car&aacute;cter personal o se cese el tratamiento de los mismos por parte de SIPYMEX SPA.</p>
<p>g. Derecho a no ser objeto de una decisi&oacute;n basada &uacute;nicamente en el tratamiento automatizado, incluida la elaboraci&oacute;n de perfiles: es el derecho de las personas usuarias a no ser objeto de una decisi&oacute;n individualizada basada &uacute;nicamente en el tratamiento automatizado de sus datos personales, incluida la elaboraci&oacute;n de perfiles, existente salvo que la legislaci&oacute;n vigente establezca lo contrario.</p>
<p>La persona usuaria podr&aacute; ejercitar sus derechos mediante comunicaci&oacute;n escrita dirigida a la persona responsable del tratamiento, conforme lo establece el art&iacute;culo 16 de la Ley N&deg; 19.628.</p>
<p>13. Enlaces a sitios web de terceros</p>
<p>El sitio web <a href="//WWW.SIPYMEX.CL">WWW.SIPYMEX.CL</a> puede incluir hiperv&iacute;nculos o enlaces que permitan acceder a p&aacute;ginas web de terceras personas distintas de SIPYMEX SPA. Las personas titulares de dichos sitios web dispondr&aacute;n de sus propias pol&iacute;ticas de privacidad y de protecci&oacute;n de datos, siendo ellos, en cada caso, responsables de sus propias bases de datos y de sus propias pr&aacute;cticas de privacidad.</p>
<p>14. Reclamaciones ante la autoridad de control</p>
<p>En caso de que la persona usuaria considere que existe un problema o infracci&oacute;n de la normativa vigente en la forma en la que se est&aacute;n tratando sus datos personales, tendr&aacute; derecho a ejercer las acciones que estime pertinentes ante los Tribunales de Justicia.</p>
<p><br></p>
<p>II. POL&Iacute;TICA DE COOKIES</p>
<p>El acceso a este sitio web puede implicar la utilizaci&oacute;n de cookies. Las cookies son peque&ntilde;as cantidades de informaci&oacute;n que se almacenan en el navegador utilizado por cada persona usuaria -en los distintos dispositivos que pueda utilizar para navegar- para que el servidor recuerde cierta informaci&oacute;n que posteriormente, y &uacute;nicamente el servidor que la implement&oacute; leer&aacute;. Las cookies facilitan la navegaci&oacute;n, la hacen m&aacute;s amigable y no da&ntilde;an el dispositivo de navegaci&oacute;n.</p>
<p>La informaci&oacute;n recolectada a trav&eacute;s de las cookies puede incluir la fecha y hora de visitas del sitio web, las p&aacute;ginas visionadas, el tiempo que ha estado en el sitio web y los sitios visitados justo antes y despu&eacute;s del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el n&uacute;mero de tel&eacute;fono de la persona usuaria o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer informaci&oacute;n del disco duro de la persona usuaria o robar informaci&oacute;n personal. La &uacute;nica manera de que la informaci&oacute;n privada de la persona usuaria forme parte del archivo cookie es que la persona usuaria d&eacute; personalmente esa informaci&oacute;n al servidor.</p>
<p>Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les ser&aacute; de aplicaci&oacute;n la Pol&iacute;tica de Privacidad anteriormente descrita. En este sentido, para la utilizaci&oacute;n de las mismas ser&aacute; necesario el consentimiento de la persona usuaria. Este consentimiento ser&aacute; comunicado, en base a una elecci&oacute;n aut&eacute;ntica, ofrecido mediante una afirmaci&oacute;n afirmativa y positiva, antes del tratamiento inicial, removible y documentado.</p>
<p>1. Cookies propias</p>
<p>Son aquellas cookies que son enviadas al ordenador o dispositivo de la persona usuaria y gestionadas exclusivamente por SIPYMEX SPA para el mejor funcionamiento del sitio web. La informaci&oacute;n que se recaba se emplea para mejorar la calidad del sitio web, su contenido y la experiencia de la persona usuaria. Estas cookies permiten reconocer a la persona usuaria como visitante recurrente del sitio web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias.</p>
<p>La o las entidades encargadas del suministro de cookies podr&aacute;n ceder esta informaci&oacute;n a terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta informaci&oacute;n para dichas entidades.</p>
<p>2. Cookies de redes sociales</p>
<p>SIPYMEX SPA incorpora plugins de redes sociales que permiten acceder a las mismas a partir del sitio web. Por esta raz&oacute;n, las cookies de redes sociales pueden almacenarse en el navegador de la persona usuaria. Las personas titulares de dichas redes sociales disponen de sus propias pol&iacute;ticas de protecci&oacute;n de datos y de cookies, siendo ellos mismos, en cada caso, responsables de sus propios ficheros o bases de datos y de sus propias pr&aacute;cticas de privacidad. La persona usuaria debe referirse a las mismas para informarse acerca de dichas cookies y, en su caso, del tratamiento de sus datos personales. &Uacute;nicamente a t&iacute;tulo informativo se indica a continuaci&oacute;n los enlaces de algunas de las redes sociales en los que se pueden consultar dichas pol&iacute;ticas de privacidad y/o de cookies:</p>
<p><a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a></p>
<p><a href="https://www.facebook.com/help/instagram/155833707900388">https://www.facebook.com/help/instagram/155833707900388</a></p>
<p><a href="https://www.youtube.com/intl/es/about/policies/#community-guidelines">https://www.youtube.com/intl/es/about/policies/#community-guidelines</a></p>
<p><a href="https://policies.google.com/privacy?hl=es">https://policies.google.com/privacy?hl=es</a></p>
<p>3. Deshabilitar, rechazar y eliminar cookies</p>
<p>La persona usuaria puede deshabilitar, rechazar y eliminar las cookies -total o parcialmente- instaladas en su dispositivo mediante la configuraci&oacute;n de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari). En este sentido, los procedimientos para rechazar y eliminar cookies pueden diferir de un navegador de internet a otro. En consecuencia, la persona usuaria debe acudir a las instrucciones facilitadas por el propio navegador de internet que est&eacute; utilizando. En el supuesto de que rechace el uso de cookies -total o parcialmente- podr&aacute; seguir usando el sitio web, aunque podr&aacute; tener limitada la utilizaci&oacute;n de algunas prestaciones del mismo.</p>
<p>III. ACEPTACI&Oacute;N Y CAMBIO DE ESTA POL&Iacute;TICA DE PRIVACIDAD</p>
<p>Es necesario que la persona usuaria haya le&iacute;do y est&eacute; conforme con las condiciones sobre la protecci&oacute;n de datos de car&aacute;cter personal contenidas en esta pol&iacute;tica de privacidad y de cookies y que acepte el tratamiento de sus datos personales para que la persona responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del sitio web implica la aceptaci&oacute;n de la pol&iacute;tica de privacidad y de cookies del mismo.</p>
<p>SIPYMEX SPA se reserva el derecho a modificar su pol&iacute;tica de privacidad y de cookies de acuerdo a su propio criterio, o motivado por un cambio legislativo o jurisprudencial. Los cambios o actualizaciones de esta pol&iacute;tica de privacidad y de cookies ser&aacute;n puestos en conocimiento de la persona usuaria.</p>
<p>Se recomienda a la persona usuaria consultar esta p&aacute;gina de forma peri&oacute;dica para estar al tanto de los &uacute;ltimos cambios o actualizaciones.</p>
<p>Esta pol&iacute;tica de privacidad y de cookies fue elaborada el d&iacute;a 1 de junio de 2022 y se encuentra actualizada para adaptarse a la legislaci&oacute;n vigente.</p>
<p><br></p>
                    
                  </div>
                 
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Acepto
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div class="text-center"></div>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data() {
    return { dialog: false, dialogs: false };
  },
};
</script>
