import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import Config from "./config";
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
import css from "@/assets/css/app.css";

import VueAxios from "vue-axios";

Vue.config.productionTip = false;
//axios.defaults.baseURL = "http://192.168.0.117:8080/";
axios.defaults.baseURL = "https://api.sipymex.cl/api/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
Vue.use(Snotify);
console.log("imprime url");
console.log(Config.baseURL);
Vue.component("Menu", () => import("./components/Layout/Menu"));
Vue.component("SideBar", () => import("./components/Layout/SideBar"));
Vue.component("Footer", () => import("./components/Layout/Footer"));
Vue.use(VueAxios, axios);

new Vue({
  vuetify,
  css,
  router,
  render: (h) => h(App),
}).$mount("#app");
